<template>
  <div class="row q-col-gutter-sm tools">
    <div class="col-12 col-sm-2 qa-pt-none">
      <q-input
        v-model="filters.reference"
        label="Reference"
      />
    </div>
    <div class="col-12 col-sm-2 qa-pt-none">
      <m-content-type-select
        v-model="filters.type"
        label="Transport type"
      />
    </div>
    <div class="col-12 col-sm-2 qa-pt-none">
      <m-user-lookup
        v-model="filters.user"
        label="Traveller"
      />
    </div>
    <div class="col-12 col-sm-2 qa-pt-none">
      <m-date-range-select
        label="Booked at"
        @change="filterByBookedAt"
      />
    </div>
    <div class="col-12 col-sm-2 qa-pt-none">
      <m-date-range-select
        label="Starts at"
        @change="filterByStartsAt"
      />
    </div>
    <div class="col-12 col-sm-2 qa-pt-none">
      <m-label-filter-select
        v-if="hasPermission('can.manage.labels')"
        v-model="filters.labels"
        label="Label filter"
      />
    </div>
  </div>
</template>
<script>
import date from 'utils/date-time'
import authentication from 'mixins/authentication'
import { MDateRangeSelect, MUserLookup, MContentTypeSelect, MLabelFilterSelect } from 'components/'

export default {
  components: {
    MUserLookup,
    MContentTypeSelect,
    MLabelFilterSelect,
    MDateRangeSelect
  },
  mixins: [authentication],
  props: ['value'],
  data () {
    return {
      filters: {}
    }
  },
  watch: {
    filters: {
      deep: true,
      handler (val, old) {
        this.$emit('input', { ...val })
      }
    }
  },
  created () {
    this.filters = { ...this.value }
  },
  methods: {
    filterByBookedAt (dates) {
      this.filters.bookedBetween = dates.start && dates.end ? `${date.toCivilDate(dates.start)} 00:00:00,${date.toCivilDate(dates.end)} 23:59:59` : null
    },
    filterByStartsAt (dates) {
      this.filters.startsBetween = dates.start && dates.end ? `${date.toCivilDate(dates.start)} 00:00:00,${date.toCivilDate(dates.end)} 23:59:59` : null
    }
  }
}
</script>
