<template>
  <m-invoices
    :load="load"
    :reset-items="() => {$store.dispatch('entities/invoices/deleteAll')}"
  />
</template>

<script>
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import { pickBy } from 'lodash'
import { MInvoices } from 'components/'

export default {
  components: { MInvoices },
  mixins: [authentication],
  computed: {
    ...mapGetters({
      consumerToken: 'partner/consumerToken'
    })
  },
  watch: {
    consumerToken: {
      deep: true,
      handler () {
        const filters = {
          ...this.filters,
          page: 1,
          limit: 20,
          orderBy: 'created_at:desc'
        }
        this.$store.dispatch('entities/invoices/deleteAll')
        this.load(pickBy(filters))
      }
    }
  },
  methods: {
    async load (filters) {
      filters = {
        ...filters,
        include: 'organisation'
      }
      if (!this.hasPermission('can.book.for.anyone') && !this.hasPermission('can.create.creditinvoices')) {
        return this.$store.dispatch('entities/invoices/loadOrganisationInvoices', {
          slug: this.$store.getters.organisation.slug,
          filters
        })
      } else {
        return this.$store.dispatch('entities/invoices/loadAllInvoices', filters)
      }
    }
  }
}
</script>
