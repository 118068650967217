<template>
  <div class="bookings-container">
    <div class="bookings">
      <m-index-list
        :load="load"
        :filters="filters"
        :sort="sort"
        :columns="columns"
        :items="items"
        :reset-items="() => {$store.dispatch('entities/bookings/deleteAll')}"
        :item-height="62"
        height="calc(100vh - 189px)"
      >
        <template v-slot:filters>
          <filters v-model="filters" />
        </template>
        <template v-slot:item="{item}">
          <booking-card :item="item" />
        </template>
      </m-index-list>
    </div>
  </div>
</template>

<script>
import { mapValues, pickBy } from 'lodash'
import filters from './filters'
import bookingCard from './booking-card'
import Booking from 'store/models/booking'
import { mapGetters } from 'vuex'
import { MIndexList } from 'components/'

export default {
  name: 'BackofficeBookings',
  components: {
    filters,
    bookingCard,
    MIndexList
  },
  data () {
    return {
      addLabelReference: null,
      showAddLabelDialog: false,
      filters: {
        reference: '',
        type: null,
        user: null,
        bookedBetween: null,
        startsBetween: null,
        labels: null
      },
      sort: {
        column: 'booked_at',
        direction: 'desc'
      },
      columns: [
        {
          label: 'Reference',
          value: 'reference',
          sortable: true
        },
        {
          label: 'Name',
          value: 'name',
          sortable: true
        },
        {
          label: 'Booked at',
          value: 'booked_at',
          sortable: true
        },
        {
          label: 'Starts at',
          value: 'starts_at',
          sortable: true
        },
        {
          label: 'Total price'
        },
        {
          label: 'Traveller'
        },
        {
          label: 'Requester'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      consumerToken: 'partner/consumerToken'
    }),
    items () {
      return Booking.query()
        .orderBy(this.sort.column, this.sort.direction)
        .get()
    }
  },
  watch: {
    consumerToken: {
      deep: true,
      handler () {
        const filters = {
          ...this.filters,
          page: 1,
          limit: 20,
          orderBy: 'created_at:desc'
        }
        this.$store.dispatch('entities/bookings/deleteAll')
        this.load(pickBy(filters))
      }
    }
  },
  created () {
    this.$store.dispatch('entities/labels/loadAllLabels')
  },
  methods: {
    async load (filters) {
      const filtersToSend = mapValues(filters, f => f.value ? f.value : f)
      return this.$store.dispatch('entities/bookings/loadAllBookings', filtersToSend)
    }
  }
}
</script>

<style lang="stylus" scoped>
.sort-icon
  position: absolute;
  top: 8px;
  font-size: 25px;

.dates
  margin-top -17px

.btn-wrapper
  margin-top 12px

.bookings
  width 100%
</style>
